import React from "react"
import "./style.scss"
import { useTranslation } from "react-i18next"
import Button from "../genericComponents/Button"
import { StaticImage } from "gatsby-plugin-image"
export default function Header(props) {
  const { t } = useTranslation()
  return (
    <section className="header-container" data-scroll-section>
      <div className="image-container" data-scroll>
        <StaticImage
          src="../../assets/images/header.jpg"
          alt={props.image.alt}
          placeholder="blurred"
          className="desktop"
        />
        <StaticImage
          src="../../assets/images/header-mobile.jpg"
          alt={props.image.alt}
          placeholder="blurred"
          className="mobile"
        />
      </div>
      <div className="header-container__background" />
      <div className="header-content" data-scroll>
        <div className="header-content__block">
          <h1 id="header-text" className="header-content__block-title">
            {t("home_header_title")}
          </h1>
          <p
            className="header-content__block-subtitle"
          >{t("home_header_subtitle")}</p>
          <Button
            label={t("button_register")}
            buttonClass="contained"
            onClick={() =>
              (window.location.href = "https://app.dardanius.com/registro")
            }
          />
          <div className="header-content__block-google-reviews">
            
          </div>
        </div>
      </div>
    </section>
  )
}
