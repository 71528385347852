import React, { useState } from "react"
import "./style.scss"

const Collapsible = props => {
    const [open, setOpen] = useState(false)
  return (
    <div className="collapsible-container">
        <div className="collapsible-header" onClick={() => setOpen(!open)}>
            <div className="collapsible-header__title">{props.title}</div>
            <div className="collapsible-header__button-content" >
              <span className={`collapsible-header__button-content-icon ${open?'open':'close'}`}></span>
            </div>
        </div>
        <div className={`collapsible-description ${!open?'is_close':''}`}>
          <p dangerouslySetInnerHTML={{__html:props.description}}/>
        </div>
    </div>
  )
}

export default Collapsible;