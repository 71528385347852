import React, { useState } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import FsLightbox from "fslightbox-react"
import Header from "../components/Header"
import Collapsible from "../components/genericComponents/Collapsible"
import Button from "../components/genericComponents/Button"
import SEO from "../components/genericComponents/SEO"
import CarouselGeneric from "../components/CarouselGeneric"
import Counters from "../components/Counters"

import Layout from "../containers/Layout"
import moment from 'moment';

//import Post from "../components/Post"
import { StaticImage } from "gatsby-plugin-image"
const headerItems = [
  {
    title: "home_feature_item1_title",
    description: "home_feature_item1_description",
    iconClass: "logo-feature1",
  },
  {
    title: "home_feature_item2_title",
    description: "home_feature_item2_description",
    iconClass: "logo-feature2",
  },
  {
    title: "home_feature_item3_title",
    description: "home_feature_item3_description",
    iconClass: "logo-feature3",
  },
]
const howItWorksItems = [
  {
    title: "home_how_it_works_items1_title",
    description: "home_how_it_works_items1_description",
    image: "how-it-works1.png",
    className: "",
  },
  {
    title: "home_how_it_works_items2_title",
    description: "home_how_it_works_items2_description",
    image: "how-it-works2.png",
    className: "reverse",
  },
  {
    title: "home_how_it_works_items3_title",
    description: "home_how_it_works_items3_description",
    image: "how-it-works3.png",
    className: "",
  },
]
const brandItems = [
  { iconClass: "sabadell", image: "banco-sabadell.png" },
  { iconClass: "caixabank", image: "banco-caixa.png" },
  { iconClass: "santander", image: "banco-santander.png" },
  { iconClass: "bbva", image: "banco-bbva.png" },
  { iconClass: "deutshebank", image: "banco-deutsche.png" },
  { iconClass: "kutxabank", image: "banco-kutxabank.png" },
  { iconClass: "unicaja", image: "banco-unicaja.png" },
  { iconClass: "targobank", image: "banco-targo.png" },
  { iconClass: "abanca", image: "banco-abanca.png" },
  { iconClass: "ibercaja", image: "banco-ibercaja.png" },
]
const reviewsItems = [
  
  {
    avatar: "E",
    name: "home_google_reviews_item5_name",
    date: "home_google_reviews_item5_date",
    description: "home_google_reviews_item5_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/cqwQ53'
  },
  {
    avatar: "X",
    name: "home_google_reviews_item8_name",
    date: "home_google_reviews_item8_date",
    description: "home_google_reviews_item8_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/4nb3jL'
  },
  {
    avatar: "J",
    name: "home_google_reviews_item6_name",
    date: "home_google_reviews_item6_date",
    description: "home_google_reviews_item6_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/7tGqh4'
  },
  {
    avatar: "A",
    name: "home_google_reviews_item9_name",
    date: "home_google_reviews_item9_date",
    description: "home_google_reviews_item9_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/VbnDYR'
  },
  {
    avatar: "J",
    name: "home_google_reviews_item7_name",
    date: "home_google_reviews_item7_date",
    description: "home_google_reviews_item7_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/uWVzGT'
  },
  {
    avatar: "M",
    name: "home_google_reviews_item4_name",
    date: "home_google_reviews_item4_date",
    description: "home_google_reviews_item4_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/SFPP3T'
  },
  {
    avatar: "V",
    name: "home_google_reviews_item3_name",
    date: "home_google_reviews_item3_date",
    description: "home_google_reviews_item3_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/8f2gKr'
  },
  {
    avatar: "M",
    name: "home_google_reviews_item2_name",
    date: "home_google_reviews_item2_date",
    description: "home_google_reviews_item2_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/QxCT89'
  },
  {
    avatar: "E",
    name: "home_google_reviews_item1_name",
    date: "home_google_reviews_item1_date",
    description: "home_google_reviews_item1_description",
    stars: [1, 1, 1, 1, 1],
    url:'https://g.co/kgs/M2miuy'
  },
]
const whyChooseUsItems = [
  {
    description: "home_why_choose_us_item1",
  },
  {
    description: "home_why_choose_us_item2",
  },
  {
    description: "home_why_choose_us_item3",
  },
  {
    description: "home_why_choose_us_item4",
  },
  {
    description: "home_why_choose_us_item5",
  },
  {
    description: "home_why_choose_us_item6",
  },
]
const negotiateItems = [
  {
    iconClass: "negotiate-column-items__icon-compra",
    title: "home_negotiate_item1",
  },
  {
    iconClass: "negotiate-column-items__icon-reformas",
    title: "home_negotiate_item2",
  },
  {
    iconClass: "negotiate-column-items__icon-autocontruccion",
    title: "home_negotiate_item3",
  },
  {
    iconClass: "negotiate-column-items__icon-extincion",
    title: "home_negotiate_item4",
  },
  {
    iconClass: "negotiate-column-items__icon-rehipoteca",
    title: "home_negotiate_item5",
  },
  {
    iconClass: "negotiate-column-items__icon-rehipoteca-reforma",
    title: "home_negotiate_item6",
  },
]

const fagsItems = [
  {
    title: "home_faqs_item1_title",
    description: "home_faqs_item1_desciption",
  },
  {
    title: "home_faqs_item2_title",
    description: "home_faqs_item2_desciption",
  },
  {
    title: "home_faqs_item3_title",
    description: "home_faqs_item3_desciption",
  },
  {
    title: "home_faqs_item4_title",
    description: "home_faqs_item4_desciption",
  },
]
const IndexPage = props => {
  const { t } = useTranslation()
  const [play, setPlay] = useState(false)
  

  return (
    <Layout
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
      <SEO
        title={t("seo_home_title")}
        desc={t("seo_home_description")}
        pathname={""}
        article={false}
        section={t("seo_home_section")}
        tags={{
          tag_1: t("seo_home_tag1"),
          tag_2: t("seo_home_tag2"),
          tag_3: t("seo_home_tag3"),
          tag_4: t("seo_home_tag4"),
        }}
      />
      <Header
        image={{
          relativePath: "header.jpg",
          alt: "Dardanius | Elige la mejor hipoteca para ti y no para tu banco",
        }}
        path={props.path}
      />
      <section className="header-items-container" data-scroll-section>
        <div className="header-items-content">
          {headerItems.map((item, index) => {
            return (
              <div key={index} className="header-items-content__item">
                <div
                  className={`header-items-content__item-icon ${item.iconClass}`}
                />
                <p className="header-items-content__item-title">
                  {t(item.title)}
                </p>
                <p>{t(item.description)}</p>
                {item.hasLink && (
                  <a href="https://app.dardanius.com/registro" rel="noreferrer">
                    {t("button_register")}
                  </a>
                )}
              </div>
            )
          })}
        </div>
      </section>
      <div className="section-how-it-works-container" data-scroll-section>
        <h2>{t("home_how_it_works_title")}</h2>
        <div className="section-how-it-works-mobile-content">
          <CarouselGeneric>
            {howItWorksItems.map((item, index) => {
              return (
                <div
                  className={`section-how-it-works-mobile-content__item`}
                  key={index}
                >
                  <div className="section-how-it-works-mobile-content__item-image">
                    {renderImageHowItWorks(item.image)}
                  </div>
                  <p className="section-how-it-works-mobile-content__item-num">{`#${
                    index + 1
                  }`}</p>
                  <p className="section-how-it-works-mobile-content__item-title">
                    {t(item.title)}
                  </p>
                  <p>{t(item.description)}</p>
                </div>
              )
            })}
            <div className={`section-how-it-works-mobile-content__item`}>
            <div className="section-how-it-works-mobile-content__item-icon" />
            <div className="section-how-it-works-mobile-content__item-title">
              {t("home_how_it_works_end_title")}
            </div>
            <p>{t("home_how_it_works_end_description")}</p>
            </div>
          </CarouselGeneric>
        </div>
        <div className="section-how-it-works-content-items">
          <div className="section-how-it-works-content-border">
            <div className={`section-how-it-works-content-border-0`} />
            <div className={`section-how-it-works-content-border-1`} />
            <div className={`section-how-it-works-content-border-2`} />
          </div>
          {howItWorksItems.map((item, index) => {
            return (
              <div
                className={`section-how-it-works-content ${item.className} row-${index}`}
                key={index}
              >
                <div className="section-how-it-works-content__item">
                  <p className="section-how-it-works-content__item-num">{`#${
                    index + 1
                  }`}</p>
                  <p className="section-how-it-works-content__item-title">
                    {t(item.title)}
                  </p>
                  <p>{t(item.description)}</p>
                </div>
                <div className="section-how-it-works-content__item-image">
                  {renderImageHowItWorks(item.image)}
                  {/*<Image src={item.image} alt={t(item.title)} />*/}
                </div>
              </div>
            )
          })}
          <div className="section-how-it-works-end">
            <div className="section-how-it-works-end__icon" />
            <div className="section-how-it-works-end__title">
              {t("home_how_it_works_end_title")}
            </div>
            <p>{t("home_how_it_works_end_description")}</p>
          </div>
        </div>
      </div>
      <div className="section-brands-container" data-scroll-section>
        <h2>{t("home_brands_title")}</h2>
        <div className="section-brands-content">
          {brandItems.map((item, index) => {
            return (
              <div key={index} className={`brand-logo`}>
                <div className={item.iconClass}>
                  {renderImageBrands(item.image)}
                  {/*<Image src={item.image} alt={item.image}/>*/}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <section className="google-reviews-container" data-scroll-section>
        <div className="google-reviews container-generic">
          <h2>{t("home_google_reviews_title")}</h2>
          {/*<p className="google-reviews__subtitle">
            {t("home_google_reviews_subtitle")}
          </p>*/}
          <div className="google-reviews__content">
            <CarouselGeneric>
              {reviewsItems.map((item, index) => {
                return (
                  <div key={index} className="google-reviews__content-item">
                    <div className="google-reviews__content-item__header">
                      <div className="google-reviews__content-item__header-avatar">
                        {item.avatar}
                      </div>
                      <div className="google-reviews__content-item__header-namedate">
                        <p className="google-reviews__content-item__header-namedate-name">
                          {t(item.name)}
                        </p>
                        <p className="google-reviews__content-item__header-namedate-date">
                          {getDateReview(t(item.date))}
                        </p>
                      </div>
                      <div className="google-reviews__content-item__header-logo" {...buttonize(() => window.open(item.url, '_blank'))}></div>
                    </div>
                    <div className="google-reviews__content-item__stars">
                      {item.stars.map((x, i) => {
                        return <div className="star" key={i}></div>
                      })}
                    </div>
                    <div className="google-reviews__content-item__description">
                      <p>{t(item.description)}</p>
                    </div>
                  </div>
                )
              })}
            </CarouselGeneric>
          </div>
          <div className="google-reviews-footer">
            {/*<p>{t("home_google_reviews_footer")}</p>*/}
            <a
              href="https://maps.app.goo.gl/nScNch6qZFezc4GZ7"
              rel="noreferrer"
              target="_blank"
            >
              {t("home_google_reviews_btn_footer")}
            </a>
          </div>
        </div>
      </section>
      <div className="why-choose-us-section" data-scroll-section>
        <div className="why-choose-us-container">
          <div className="why-choose-us-content">
            <div className="why-choose-us-content__image">
              <StaticImage
                src="../assets/images/porque-escoger-dardanius.png"
                alt={t("home_why_choose_us_title")}
                placeholder="blurred"
              />
              {/*<Image
                src="porque-escoger-dardanius.png"
                alt={t("home_why_choose_us_title")}
            />*/}
            </div>
            <div className="why-choose-us-content__items">
              <h2>{t("home_why_choose_us_title")}</h2>
              <div className="why-choose-us-content__items-content">
                {whyChooseUsItems.map((item, i) => {
                  return (
                    <div key={i} className="why-choose-us-content__item">
                      <div className="why-choose-us-content__item-icon" />
                      <p
                        className="why-choose-us-content__item-description"
                        dangerouslySetInnerHTML={{
                          __html: t(item.description),
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Counters/>
      <div className="negotiate-container" data-scroll-section>
        <div className="negotiate-column-title">
          <h2>{t("home_negotiate_title")}</h2>
          <Button
            label={t("home_negotiate_btn")}
            buttonClass="contained"
            onClick={() =>
              (window.location.href = "https://app.dardanius.com/registro")
            }
          />
        </div>
        <div className="negotiate-column-items">
          {negotiateItems.map((item, x) => {
            return (
              <div key={x} className="negotiate-column-items__container">
                <div className="negotiate-column-items__content">
                  <div className={item.iconClass} />
                  <p
                    className="negotiate-column-items__item-title"
                    dangerouslySetInnerHTML={{ __html: t(item.title) }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="video-container-background" data-scroll-section>
        <div className="video-container">
          <h2>{t("home_video_title")}</h2>
          <p>{t("home_video_subtitle")}</p>
          <div className="video-content">
            <StaticImage
              src="../assets/images/video-cover.png"
              alt={t("home_video_title")}
              placeholder="blurred"
            />
            {/*<Image src="video-cover.png" alt={t("home_video_title")} />*/}
            <div className="video-content__foreground">
              <div
                aria-label="play video"
                className="video-content__foreground-icon"
                {...buttonize(() => setPlay(!play))}
              />
            </div>
          </div>
        </div>
      </div>
      <FsLightbox
        toggler={play}
        sources={[
          <div className="video-player">
            <iframe
              src="https://blog.dardanius.com/wp-content/uploads/2022/08/video-home.mp4"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen="0"
              title="Dardanius"
              data-ready="true"
            ></iframe>
          </div>,
        ]}
      />
      {/* TODO: Disable Blog
      <section className="latest-posts-container" data-scroll-section>
        <div className="blog-item  container-generic">
          <div className="latest-posts-header">
            <div className="latest-posts-header-texts">
              <h2>{t("home_latest_posts_title")}</h2>
              <p>{t("home_latest_posts_subtitle")}</p>
            </div>
            <div className="latest-posts-container-button-desktop">
              <Button
                label={t("home_latest_posts_btn")}
                role="link"
                aria-label="go to blog"
                onClick={() => navigate("/blog/")}
                hasArrow={true}
              />
            </div>
          </div>
          <div className="blog-item__content">
            <CarouselGeneric>
              {props.data.posts.nodes.map((post, index) => {
                return (
                  <div key={index}>
                    <Post post={post} />
                  </div>
                )
              })}
            </CarouselGeneric>
          </div>
          <div className="latest-posts-container-button-mobile">
            <Button
              label={t("home_latest_posts_btn")}
              role="link"
              aria-label="go to blog"
              onClick={() => navigate("/blog/")}
              hasArrow={true}
            />
          </div>
        </div>
      </section>*/}
      <div className="faqs-section-container" data-scroll-section>
        <h2>{t("home_faqs_title")}</h2>
        <div className="faqs-section-content">
          <div className="faqs-section-content-column">
            <Collapsible
              title={t(fagsItems[0].title)}
              description={t(fagsItems[0].description)}
            />
            <Collapsible
              title={t(fagsItems[1].title)}
              description={t(fagsItems[1].description)}
            />
          </div>
          <div className="faqs-section-content-column">
            <Collapsible
              title={t(fagsItems[2].title)}
              description={t(fagsItems[2].description)}
            />
            <Collapsible
              title={t(fagsItems[3].title)}
              description={t(fagsItems[3].description)}
            />
          </div>
        </div>
      </div>
    </Layout>
  )

  function getDateReview(date){
    const dateMoment = moment(date).startOf('day')
    const today = moment().startOf('day');

    const difYears = today.diff(dateMoment, 'years');
    if(difYears > 0){
       if(difYears === 1){
        return t('home_google_reviews_date_year')
       }else{
        return t('home_google_reviews_date_years', {data:difYears})
       } 
    }else{
      const difMonths = today.diff(dateMoment, 'months');
      if(difMonths > 0){
        if(difMonths === 1){
          return t('home_google_reviews_date_month')
         }else{
          return t('home_google_reviews_date_months', {data:difMonths})
         }
      }else{
        const difWeeks = today.diff(dateMoment, 'weeks');
        if(difWeeks > 0){
          if(difWeeks === 1){
            return t('home_google_reviews_date_week')
           }else{
            return t('home_google_reviews_date_weeks', {data:difWeeks})
           }
        }
      }
    }
  }

  function renderImageHowItWorks(path) {
    switch (path) {
      case "how-it-works1.png":
        return (
          <StaticImage
            src="../assets/images/how-it-works1.png"
            alt="Dardanius | solicitud online"
            placeholder="blurred"
          />
        )
      case "how-it-works2.png":
        return (
          <StaticImage
            src="../assets/images/how-it-works2.png"
            alt="Dardanius | documentacion"
            placeholder="blurred"
          />
        )
      case "how-it-works3.png":
        return (
          <StaticImage
            src="../assets/images/how-it-works3.png"
            alt="Dardanius | propuestas"
            placeholder="blurred"
          />
        )
      default:
        break
    }
  }

  function renderImageBrands(path) {
    switch (path) {
      case "banco-sabadell.png":
        return (
          <StaticImage
            src="../assets/images/banco-sabadell.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={132}
            height={25}
          />
        )
      case "banco-caixa.png":
        return (
          <StaticImage
            src="../assets/images/banco-caixa.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={120}
            height={27}
          />
        )
      case "banco-santander.png":
        return (
          <StaticImage
            src="../assets/images/banco-santander.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={132}
            height={23}
          />
        )
      case "banco-bbva.png":
        return (
          <StaticImage
            src="../assets/images/banco-bbva.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={86}
            height={26}
          />
        )
      case "banco-deutsche.png":
        return (
          <StaticImage
            src="../assets/images/banco-deutsche.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={140}
            height={25}
          />
        )
      case "banco-kutxabank.png":
        return (
          <StaticImage
            src="../assets/images/banco-kutxabank.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={91}
            height={64}
          />
        )
      case "banco-unicaja.png":
        return (
          <StaticImage
            src="../assets/images/banco-unicaja.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={120}
            height={30}
          />
        )
      case "banco-targo.png":
        return (
          <StaticImage
            src="../assets/images/banco-targo.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={134}
            height={19}
          />
        )
      case "banco-abanca.png":
        return (
          <StaticImage
            src="../assets/images/banco-abanca.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={108}
            height={17}
          />
        )
      case "banco-ibercaja.png":
        return (
          <StaticImage
            src="../assets/images/banco-ibercaja.png"
            alt="how-it-works1.png"
            placeholder="blurred"
            layout="fixed"
            width={120}
            height={25}
          />
        )
      default:
        break
    }
  }

  function buttonize(handlerFn) {
    return {
      role: "button",
      onClick: handlerFn,
      onKeyDown: event => {
        if (event.keycode === 13) handlerFn(event)
      },
    }
  }
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
/**
 * QUERY CON BLOG
 * export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    posts: allWpPost(limit: 3, sort: { fields: [date], order: DESC }) {
      nodes {
        title
        slug
        uri
        isSticky
        excerpt
        categories {
          nodes {
            id
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 50
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 533
                  width: 900
                  formats: [AUTO, AVIF, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`

 */