import PropTypes from "prop-types"
import React from "react"
import "./style.scss"
import UserIcon from "../../../../svg/user-icon.inline.svg"
import ArrowIcon from "../../../../svg/arrow-right-icon.inline.svg"
const Button = props => {
  return (
      <button
        className={`button-generic ${props.buttonClass} ${props.hasArrow?'has-arrow':''}`}
        aria-label={props.label}
        disabled={props.disabled}
        onClick={() => {
          if (props.onClick) {
            props.onClick()
          }
        }}
      >
        <span className={`button-generic__label`}>
          {props.iconUser && <span className="button-generic__label-icon">
            <UserIcon/>
          </span>}
          {props.label}
          {props.hasArrow && <ArrowIcon/>}
        </span>
      </button>
  )
}

export default Button

Button.propTypes = {
  label: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
  onClick: PropTypes.func,
  iconUser: PropTypes.bool,
  hasArrow: PropTypes.bool,
}

Button.defaultProps = {
  label: "button",
  labelClass: "color-black-text",
  buttonClass: "",
  iconUser: false,
  hasArrow:false
}
