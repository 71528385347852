import React from "react"
import { useTranslation } from "react-i18next"
import { useCountUp } from "react-countup"
import { InView } from "react-intersection-observer"
import { StaticImage } from "gatsby-plugin-image"
const counterItems = [
    { label: "home_counter_items_1" },
    { label: "home_counter_items_2" },
    { label: "home_counter_items_3" },
  ]
export default function Counters(props) {
  const { t} = useTranslation()
  const counter0 = useCountUp({
    ref: "counter0",
    end: 10000,
    duration: 2,
    prefix: "+",
    separator: ".",
  })
  const counter1 = useCountUp({
    ref: "counter1",
    end: 15,
    duration: 2,
    prefix: "+",
    separator: ".",
  })
  const counter2 = useCountUp({
    ref: "counter2",
    end: 17,
    duration: 2,
    prefix: "+",
    separator: ".",
  })
  return (
    <InView
        as="div"
        triggerOnce
        onChange={(inView, entry) => {
          if (inView) {
            counter0.start()
            counter1.start()
            counter2.start()
          }
        }}
      >
        <section className="section-counters-container" data-scroll-section>
          <StaticImage
            src="../../assets/images/background-counters.png"
            alt="dardanius"
            placeholder="blurred"
            className="desktop"
          />
          <StaticImage
            src="../../assets/images/background-counters-mobile.jpg"
            alt="dardanius"
            placeholder="blurred"
            className="mobile"
          />
          <div className="section-counters-bg"></div>
          <div
            className="section-counters-content"
            data-scroll
            data-scroll-call="counter-start"
          >
            {counterItems.map((item, i) => {
              return (
                <div key={i} className="section-counters-content__item-content">
                  <div className="section-counters-content__item-content-wrapper">
                    <div
                      className="section-counters-content__item-content-wrapper-counter"
                      id={`counter${i}`}
                    />
                    <p>{t(item.label)}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </InView>
  )
}
