import React from "react"
import "./style.scss"
import Swiper from "react-id-swiper"

export default function CarouselGeneric(props) {
  const params = {
    breakpoints: {
      // when window width is >= 480px
      0: {
        slidesPerView: 1,
        spaceBetween: 18,
      },
      // when window width is >= 640px
      767: {
        slidesPerView: 2,
        spaceBetween: 18,
      },
      899: {
        slidesPerView: 3,
        spaceBetween: 18,
      },
      1440: {
        slidesPerView: 3,
        spaceBetween: 18,
      },
      1920: {
        slidesPerView: 3,
        spaceBetween: 18,
      },
    },
    autoHeight: true, //enable auto height
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    containerClass:'.swiper-container-generic'
  }
  
  if (typeof window === "undefined" || !window.document) {
    return null
  }else{
    return (
      <div className="carousel-container-generic">
        <div className="carousel-content-generic">
          <Swiper {...params} virtual={typeof window !== "undefined" ? false : true}>
           {props.children}
          </Swiper>
        </div>
      </div>
    )
  }
  
}
